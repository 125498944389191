$bg-pink-light: #fffcfc;
$bg-blue-light: #f7fafc;

$c-blue-primary: #4789ff;
$c-blue-22: #224a9d;
$c-blue-21: #213a6c;
$c-blue-68: #687398;
$c-blue-7a: #7a92c4;
$c-blue-dark: #1a1f36;

$bo-input: #b8c8e9;
$bo-blue-focus: #4679d5;
