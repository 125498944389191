@import '~bootstrap/scss/bootstrap';

@import './base/settings';
@import './base/base';

// Components
@import './components/buttons';
@import './components/dashboard';
@import './components/forms';
@import './components/login';
@import './components/modals';
@import './components/navbar';

@font-face {
  font-family: 'Recoleta';
  src: local('Recoleta'), url('./fonts/Recoleta-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'SofiaPro';
  src: local('SofiaPro'),
    url('./fonts/Sofia_Pro_Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'SofiaProMedium';
  src: local('SofiaProMedium'),
    url('./fonts/Sofia_Pro_Medium.ttf') format('truetype');
  font-weight: normal;
}
