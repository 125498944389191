*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
}

html, body{
  height: 100vh;
  width: 100vw;
  margin: 0;
  color: $c-blue-primary;
  font-weight: 400;
  font-size: 14px;
}
h1, h2, h3, h4, p, span, ul{
  margin-bottom: 0;
}

a{
  color: $c-blue-primary;
  text-decoration: none;
  &:hover, &:focus{
    color: darken($color: $c-blue-primary, $amount: 20);
    text-decoration: none;
  }
}
ul{
  padding: 0;
}
li{
  list-style: none;
}
p{
  margin-bottom: 0;
}
input,
select{
  border: 1px solid $bo-input;
  box-shadow: none;
  &:hover, &:focus{
      outline: none;
      border: 1px solid darken($color: $bo-input, $amount: 20) ;
  }
}

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
  &.space-between{
    justify-content: space-between;
  }
  &.start{
    justify-content: start;
  }
  &.end{
    justify-content: flex-end;
  }
  &.align-start{
    align-items: flex-start;
  }
  &.column{
    flex-direction: column;
  }
  .flex33{
    width: 33%;
  }
  .flex50{
    flex: 1 1 50%;
  }
  .flex-auto{
    flex: 1 1 auto;
  }
  .strech-self{
    align-self: stretch;
  }
}
.pointer{
  cursor: pointer;
}
.dropdown-item{
  color: $c-blue-primary;
  font-size: 14px;
}
.rotate180{
  transform: rotate(180deg);
}
