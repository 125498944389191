.dashboard__navbar {
  align-items: center;
  background: #fff;
  box-shadow: 0 1px #e3e8ee;
  // border-bottom: 1px solid #e3e8ee;
  box-shadow: 0px 1px 10px #e9ecf3;
  display: flex;
  height: 90px;
  justify-content: flex-end;
  // max-width: 1420px;
  min-width: 816px;
  padding: 0 50px;
  position: fixed;
  top: 0;
  width: calc(100vw);
  z-index: 11;
  .navbar__content {
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .navbar__icon {
    background: none;
    border: none;
    color: $c-blue-22;
    height: 100%;
    padding: 0;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SofiaPro', sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    svg {
      margin-left: 10px;
    }
    &:hover {
      color: darken($color: $c-blue-68, $amount: 20);
    }
    img {
      margin-left: 10px;
      height: 13px;
    }
  }
  #dropdown-basic.dropdown-toggle {
    background: none !important;
    color: $c-blue-68 !important;
    &:focus,
    &:hover {
      outline: none;
      background: none;
      border: none;
      box-shadow: none;
      color: darken($color: $c-blue-68, $amount: 20);
    }
    &::after {
      display: none !important;
    }
  }
  .navbar__user-info {
    padding: 5px 14px;
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      color: $c-blue-22;
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      color: $c-blue-primary;
    }
  }
}
