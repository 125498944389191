.MuiOutlinedInput-notchedOutline {
  border-color: #e3e8ed !important;
  //border-color: transparent !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #f8f9f9 !important;
  top: -4px;
  padding: 0 10px;
}
.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  color: $c-blue-68;
}
input:focus,
input:hover {
  outline: none;
  border: none;
  //border-color: #e3e8ed;
}
