.dashboard__sidebar {
  background: $bg-blue-light;
  border-right: 1px solid #e3e8ee;
  bottom: 0;
  color: $c-blue-primary;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  width: 270px;
  z-index: 10;
}

.dashboard__wrapper {
  height: 100vh;
  margin-left: auto;
}
.dashboard__panel {
  margin: 0 auto;
  // max-width: 1420px;
  padding: 92px 40px 48px;
  position: relative;
}
.dashboard__partners {
  background: #f9f9f9;
  overflow: auto;
  .dashboard__title {
    color: $c-blue-22;
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 120%;
    margin-top: 70px;
    margin-bottom: 40px;
  }
}

.dashboard__title {
  box-shadow: inset 0 -1px #e3e8ee;
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 16px;
  margin-bottom: 40px;
  &.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.loadingContainer {
  position: relative;
  height: 100%;
  min-height: 200px;
}
.loading {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  svg {
    color: $c-blue-primary;
  }
  h5 {
    margin-top: 20px;
    color: $c-blue-primary;
  }
}
.dashboard__header {
  margin-bottom: 20px;
  .next-month,
  .prev-month {
    width: 20px;
    padding: 0;
    margin: 0 10px;
    img {
      height: 20px;
    }
  }
  .create-pdf {
    color: $c-blue-primary;
  }
  p {
    font-family: 'SofiaPro', sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    color: $c-blue-22;
  }
  .flex .group {
    margin-left: 10px;
  }
}

.dashboard__content {
  max-width: 1000px;
  margin: 20px auto;
  .dashboard__main_content {
    flex: 1 1 auto;
    padding-right: 20px;
  }
  .dashboard__aside {
    width: 30%;
  }
  .card-white {
    background: #ffffff;
    box-shadow: 0px 1px 10px #e9ecf3;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 20px;
    .card-white__title {
      font-family: 'SofiaProMedium', sans-serif;
      font-size: 16px;
      line-height: 120%;
      color: $c-blue-22;
      text-align: center;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
  }
}
.dashboard__resume_numbers {
  margin-bottom: 15px;
  .resume-box-number {
    height: 120px;
    box-shadow: 0px 1px 10px #e9ecf3;
    border-radius: 14px;
    background: #fff;
    color: $c-blue-22;
    width: 100%;
    display: flex;
    padding: 18px;
    img {
      margin-right: 15px;
      width: 90px;
    }
    &:not(:last-child) {
      margin-right: 12px;
    }
    &.blue {
      color: #fff;
      background: #2150ac;
    }
    .flex {
      flex-direction: column;
      align-items: self-start;
      span {
        font-size: 32px;
        line-height: initial;
      }
      p {
        font-size: 15px;
      }
    }
  }
}
.direct_roi {
  margin-right: 20px;
  padding: 20px 35px !important;
  .flex {
    align-items: flex-start;
  }
  img {
    width: 37px;
    margin-right: 13px;
  }
  .div-info-line {
    margin-bottom: 25px;
    > .flex {
      padding-right: 14px;
    }
  }
  .number_exp {
    span {
      font-family: 'SofiaPro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: $c-blue-22;
    }
    p {
      font-family: 'SofiaPro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 120%;
      color: $c-blue-7a;
    }
  }
}
.demographics {
  width: 36%;
  margin-left: 12px;
}
.top_contents {
  text-align: left;
  .top_content {
    width: 100%;
    .top_content_text {
      width: calc(100% - 70px);
    }
  }
  .img {
    height: 70px;
    width: 70px;
    border-radius: 6px;
    margin-right: 11px;
  }
  .title {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: $c-blue-22;
  }
  .desc {
    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 120%;
    color: #9ba3bc;
  }
}
.top_moods-container {
  margin-right: 12px;
}
.top_moods {
  display: flex;
  text-align: center;
  .top_mood {
    width: 33%;
    padding: 2%;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family: 'SofiaPro', sans-serif;
      font-size: 13px;
      line-height: 120%;
      color: #687398;
    }
  }
  .mood_img {
    width: 60px;
    height: 60px;
    background: #f8f8f8;
    border-radius: 50%;
    line-height: 60px;
    margin: 0 auto 10px;
    img {
      width: 30px;
    }
  }
}

#partner-metric,
#partner-metric-label,
.MuiAutocomplete-popper > div {
  color: $c-blue-22;
}
