.login {
  background: $bg-pink-light;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.login__logos {
  img {
    width: 70px;
  }
}

.login__container {
  width: 70%;
}

.login__box-white {
  background: #fff;
  width: 100%;
  height: 551px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 10px #e9ecf3;
  border-radius: 1px;
  margin-top: 20px;
}

.login__image,
.login__form {
  height: 100%;
  width: 50%;
}

.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 44px;
  h3 {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;

    color: $c-blue-22;
    margin-bottom: 30px;
  }

  input {
    display: block;
    width: 100%;
    height: 40px;
    background: #ffffff;
    /* Primary medium */

    border: 1px solid $bo-input;
    box-sizing: border-box;
    border-radius: 6px;

    font-family: 'SofiaPro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;

    color: $c-blue-22;
    padding-left: 14px;
    &::placeholder {
      color: $c-blue-68;
    }
    &:focus {
      border-color: $bo-blue-focus;
    }
  }
  button {
    min-height: 40px;
    height: 40px;
  }
  .auth__input {
    margin-bottom: 24px;
  }
}

.invalid-feedback {
  width: 100%;
  margin: 0.4rem;
  font-size: 80%;
  color: #dc3545;
}

.error-text {
  color: #cd3d64;
  margin-bottom: 15px;
}

@media (max-width: 850px) {
  .login__container {
    width: 90%;
    height: 90%;
  }
}

@media (max-width: 650px) {
  .login {
    height: 900px;
  }
  .login__container {
    width: 90%;
    height: 90%;
  }
  .login__box-white {
    flex-direction: column;
    height: auto;
  }
  .login__image {
    width: 80%;
    min-height: 450px;
    margin-bottom: 20px;
  }
  .login__form {
    width: 100%;
    padding: 20px 10%;
  }
}

@media (max-width: 420px) {
  .login {
    height: 750px;
  }
  .login__image {
    min-height: 350px;
  }
  .login__form {
    input {
      font-size: 12px;
    }
    h3 {
      font-size: 30px;
      margin-bottom: 20px;
    }
    button {
      width: 100%;
    }
  }
}
