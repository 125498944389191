/* Modal */
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: white;
  border-radius: 5px;
  color: rgb(51, 51, 51);
  display: inline;
  max-height: 520px;
  max-width: 500px;
  outline: none;
  padding: 10px;
  padding: 0 !important;
  min-width: 70%;
  .container {
    padding: 0;
    height: 100%;
    position: relative;
  }
  .close-modal {
    position: absolute;
    right: 0;
    color: $c-blue-22;
  }
}
.modal__header,
.modal__body,
.modal__footer {
  padding: 0 20px;
}
.no-header .modal__body {
  height: 100%;
  padding: 30px;
}
.modal__body {
  background-color: $bg-blue-light;
  height: calc(100% - 56px - 60px);
  overflow-y: scroll;
  display: flex;
  align-items: center;
  ul {
    padding: 10px 30px;
    li {
      h4 {
        font-family: 'SofiaPro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: $c-blue-22;
        margin-bottom: 7px;
      }
      p {
        font-family: 'SofiaPro', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: $c-blue-21;
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .modal__body {
    align-items: flex-start;
  }
}
@media (max-width: 789px) {
  .mobile-column {
    flex-direction: column;
  }
  .direct_roi {
    margin-right: 0;
  }
  .demographics {
    width: 100%;
    margin-left: 0;
  }
  .dashboard__resume_numbers .resume-box-number {
    height: 200px;
    flex-direction: column;
    align-items: center;
    .flex {
      align-items: center;
      p {
        text-align: center;
      }
    }
    img {
      margin-right: 0;
      width: auto;
    }
  }
}
